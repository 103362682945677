// Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedinIn, faThreads } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components/macro';
import company from '../../../shareables/company';
import Logo from './logo';
import { faPhone } from '@fortawesome/pro-solid-svg-icons';


// Styled components
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.9375rem;
	color: hsl(206, 25%, 20%);
	
	margin: 0 auto;
`;

const SupportNumber = styled.a`
	color: inherit;
	text-decoration-line: none;
	font-size: 1rem;
	line-height: 1;
	
	.fa-phone {
		margin-right: 0.25rem;
	}
`;

const Socials = styled.div`
	display: flex;
	align-items: center;
	gap: 0.9375rem;
`;

const SocialIcon = styled.a`
	color: inherit;
	text-align: center;
	font-size: 1.5rem;
	line-height: 1;
`;

const SparksuiteCompany = styled.span`
	font-size: 0.875rem;
	line-height: 1;
`;

const Sparksuite = styled.a`
	color: inherit;
	text-decoration-line: underline;
	
	&:hover {
		text-decoration: none;
	}
`;


// Function component
const BrandLinks = () => (
	<Wrapper>
		<Logo />
		
		{company.supportNumber && (
			<SupportNumber href={`tel:${company.supportNumber.replace(/[^+0-9]/g, '')}`}>
				<FontAwesomeIcon icon={faPhone} />
				{company.supportNumber}
			</SupportNumber>
		)}
		
		<Socials>
			{company.socialSlugs.facebook && (
				<SocialIcon
					href={`https://www.facebook.com/${company.socialSlugs.facebook}`}
					target='_blank'
					rel='noopener noreferrer'
					aria-label={`${company.name} on Facebook`}
				>
					<FontAwesomeIcon icon={faFacebook} />
				</SocialIcon>
			)}
			
			{company.socialSlugs.linkedIn && (
				<SocialIcon
					href={`https://www.linkedin.com/company/${company.socialSlugs.linkedIn}`}
					target='_blank'
					rel='noopener noreferrer'
					aria-label={`${company.name} on LinkedIn`}
				>
					<FontAwesomeIcon icon={faLinkedinIn} />
				</SocialIcon>
			)}
			
			{company.socialSlugs.threads && (
				<SocialIcon
					href={`https://www.threads.net/@${company.socialSlugs.threads}`}
					target='_blank'
					rel='noopener noreferrer'
					aria-label={`${company.name} on Threads`}
				>
					<FontAwesomeIcon icon={faThreads} />
				</SocialIcon>
			)}
		</Socials>
		
		<SparksuiteCompany>
			A{' '}
			<Sparksuite target='_blank' rel='noopener' href='https://www.sparksuite.com/'>
				Sparksuite
			</Sparksuite>{' '}
			company
		</SparksuiteCompany>
	</Wrapper>
);

export default BrandLinks;
