// Imports
import styled from 'styled-components/macro';
import subscriptionSizes from '../../../../shareables/utils/billing/subscription-sizes';
import { FeatureGroup } from './feature-groupings';
import FeatureRow from './row';


// Styled components
const BaseCell = styled.th`
	border-bottom: 0.0625rem solid hsl(206, 69%, 87%);
	font-weight: 600;
	line-height: 1.2;
	
	padding-bottom: 0.625rem;
`;

const Heading = styled(BaseCell)`
	min-width: 8.72rem;
	width: 57%;
	font-size: clamp(1.125rem, 4.17vw, 2rem);
	text-align: left;
`;

const PlanName = styled(BaseCell)`
	min-width: 5.484rem;
	width: 21.5%;
	text-align: center;
	font-size: clamp(0.84375rem, 3.125vw, 1.5rem);
`;

const Wrapper = styled.div`
	width: 100%;
	max-width: 100%;
	overflow: auto;
	overflow-y: hidden;
`;

const Container = styled.table`
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	
	a {
		color: inherit;
		text-decoration: underline;
		
		&:hover {
			text-decoration: none;
		}
	}
`;


// Define the accepted props
interface Props extends FeatureGroup {
	size: (typeof subscriptionSizes)[number];
}


// Function component
const ComparisonTable: React.FC<Props> = ({ size, label, features }) => (
	<Wrapper>
		<Container>
			<thead>
				<tr>
					<Heading scope='col'>{label}</Heading>
					<PlanName scope='col'>Free</PlanName>
					<PlanName scope='col'>Deluxe</PlanName>
				</tr>
			</thead>
			
			<tbody>
				{features.map((feature, i) => (
					<FeatureRow key={i} {...feature} size={size} />
				))}
			</tbody>
		</Container>
	</Wrapper>
);

export default ComparisonTable;
