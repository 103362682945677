// Imports
import styled from 'styled-components/macro';
import QuotationMark from './quotation-mark';


// Styled components
const Container = styled.blockquote`
	position: relative;
	
	max-width: 21.25rem;
	
	border-radius: 0.3125rem;
	border: 0.125rem solid hsl(206, 69%, 87%);
	
	padding: 1rem 1.25rem;
	
	color: hsl(206, 25%, 20%);
	font-size: 1.125rem;
	font-weight: 400;
`;

const AttributionContainer = styled.footer`
	position: absolute;
	right: 1.1875rem;
	bottom: -0.67rem;
	z-index: 2;
`;

const Attribution = styled.cite`
	background-color: #fff;
	color: hsl(206, 69%, 35%);
	text-align: right;
	font-size: 1.125rem;
	font-weight: 400;
	font-style: normal;
	white-space: nowrap;
	
	max-width: calc(100% - 1.325rem);
	text-overflow: ellipsis;
	overflow: hidden;
	
	padding: 0rem 0.3125rem;
`;

const HOA = styled.a`
	color: inherit;
	text-decoration: underline;
	
	&:hover {
		text-decoration: none;
	}
`;


// Define the accepted props
export interface Props {
	text: string;
	source: {
		name: string;
		hoa: string;
		url: string;
	};
}


// Function component
const Quote: React.FC<Props> = ({ text, source }) => (
	<li>
		<Container>
			<QuotationMark position='beginning' />
			<p>{text}</p>
			
			<AttributionContainer>
				<Attribution>
					{source.name},{' '}
					<HOA href={source.url} target='_blank' rel='noopener noreferrer'>
						{source.hoa}
					</HOA>
				</Attribution>
			</AttributionContainer>
			<QuotationMark position='end' />
		</Container>
	</li>
);

export default Quote;
