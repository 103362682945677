// Imports
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lazy, Suspense, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { QuestionAndAnswer } from './questions-and-answers';


// Lazily-loaded components
const AnimateHeight = lazy(() => import('react-animate-height'));


// Styled components
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	
	padding: 1.25rem 0;
	
	border-bottom: 0.0625rem solid hsl(206, 69%, 87%);
	
	&:first-child {
		padding-top: 0;
	}
`;

const Answer = styled.div`
	padding-top: 0.625rem;
	line-height: 1.2;
`;

const DisclosureButton = styled.button<{ isOpen: boolean }>`
	border: 0;
	padding: 0;
	margin: 0;
	background-color: transparent;
	color: inherit;
	appearance: none;
	cursor: pointer;
	
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	
	font-size: 1.125rem;
	font-weight: 600;
	
	text-align: left;
	
	> svg {
		will-change: transform;
		transition: transform 0.2s ease-in-out;
		line-height: 1;
		font-weight: 400;
	}
	
	${({ isOpen }) =>
		isOpen
			? css`
					> svg {
						transform: rotate(-180deg);
					}
				`
			: ''}
`;


// Define the accepted props
interface Props extends QuestionAndAnswer {
	storybookIsOpen?: boolean;
}


// Function component
const Disclosure: React.FC<Props> = ({ question, answer, storybookIsOpen }) => {
	// Use state
	const [isOpen, setIsOpen] = useState(storybookIsOpen ?? false);
	
	
	// Build answer ID
	const answerID = `${question
		.toLowerCase()
		.slice(0, question.length - 1)
		.replace(/ /g, '-')}-answer`;
	
	
	// Return JSX
	return (
		<Wrapper>
			<DisclosureButton
				isOpen={isOpen}
				onClick={() => setIsOpen(!isOpen)}
				aria-expanded={isOpen}
				aria-controls={answerID}
			>
				{question}
				<FontAwesomeIcon icon={faChevronDown} />
			</DisclosureButton>
			
			<Suspense fallback={<Answer style={{ display: isOpen ? 'block' : 'none' }}>{answer}</Answer>}>
				<AnimateHeight height={isOpen ? 'auto' : 0} id={answerID} duration={400} animateOpacity>
					<Answer>{answer}</Answer>
				</AnimateHeight>
			</Suspense>
		</Wrapper>
	);
};

export default Disclosure;
